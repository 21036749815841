import Axios, { AxiosInstance } from 'axios';
import { InvalidTokenError } from 'jwt-decode';
import { SecurityPolicy } from '../auth';
import { EBAPIError } from './errors';

type AuthToken = {
  exp: number;
  accessToken: string;
  refreshToken: string;
  idToken?: string;
};

const AUTH_TOKEN_REFRESH_TIMEOUT = 10000;

export type EBGlobalConfig = {
  engineUrl?: string;
  api: string;
  appName: string;
  version: string;
  cacheVersion: string;
  /**
   *  { langCode: module path or langKeys object }
   */
  languages: Record<string, string | Record<string, string>>;
  externals?: Record<string, unknown>;
  constant?: Record<string, unknown>;
  securityPolicies: Record<string, SecurityPolicy>;
  theme: Readonly<{
    default: string;
    support: string[];
    customThemes?: ({ name: string } & Record<string, object>)[] | null;
  }>;
};

export class HttpClient {
  private axios: AxiosInstance;
  private storageCacheKey = '';
  baseUrl: string;

  private authTokensPolicies: Record<string, AuthToken> = {};
  private authTokensGroups: Record<string, AuthToken> = {};
  private authPolicyGroups: Record<string, string[]> = {};

  constructor(private config: EBGlobalConfig) {
    this.storageCacheKey = `__eb_auth_v2_policies_${config.appName}`;
    this.baseUrl = `${config.engineUrl || '/'}${config.appName}${config.api}`;
    this.axios = Axios.create({
      baseURL: this.baseUrl
    });

    try {
      this.authTokensPolicies = JSON.parse(localStorage.getItem(this.storageCacheKey) ?? '{}');
    } catch (error) {
      localStorage.removeItem(this.storageCacheKey);
    }

    this.authPolicyGroups = Object.entries(config.securityPolicies).reduce(
      (prev, [policyName, policyConfig]) => {
        if ('group' in policyConfig && policyConfig['group']) {
          const group = policyConfig['group'];

          if (!prev[group]) {
            prev[group] = [];
          }

          if (!prev[group].includes(policyName)) {
            prev[group].push(policyName);
          }
        }

        return prev;
      },
      {} as Record<string, string[]>
    );

    Object.entries(this.authPolicyGroups).forEach(([groupName, policies]) => {
      const groupCacheKey = `__eb_auth_v2_policy_groups_${groupName}`;

      try {
        const cacheStr = localStorage.getItem(groupCacheKey);

        if (!cacheStr) {
          return;
        }

        this.authTokensGroups[groupCacheKey] = JSON.parse(cacheStr);

        policies.forEach((policy) => {
          this.authTokensPolicies[policy] = this.authTokensGroups[groupCacheKey];
        });
      } catch (error) {
        localStorage.removeItem(groupCacheKey);
      }
    });

    Object.entries(this.authPolicyGroups).forEach(([groupName, policies]) => {
      window.addEventListener('storage', (event) => {
        if (
          event.storageArea != localStorage ||
          event.key !== `__eb_auth_v2_policy_groups_${groupName}`
        ) {
          return;
        }

        console.log(
          `__eb_auth_v2_policy_groups_${groupName} has been changed, updating ${policies.join(
            ','
          )}...`
        );

        if (event.newValue) {
          const { accessToken, exp, refreshToken, idToken } = JSON.parse(
            event.newValue
          ) as AuthToken;

          policies.forEach((policy) =>
            this.updateAuthTokensForPolicies(policy, accessToken, refreshToken, exp, idToken)
          );

          if (!event.oldValue) {
            // reload only oldValue is empty
            window.location.reload();
          }
        } else {
          policies.forEach((policy) => {
            this.removeAuthTokensForPolicy(policy);
            window.EB.auth.logout(policy);
          });
        }
      });
    });

    this.autoRefreshToken = this.autoRefreshToken.bind(this);

    this.axios.interceptors.request.use(async (axiosConfig) => {
      axiosConfig.headers = {
        ...axiosConfig.headers,
        'X-EB-LANGUAGE': window.EB.intl.selectedLang.toLowerCase()
      };

      const authHeaders = await Promise.all(
        Object.entries(this.authTokensPolicies).map(([policy, tokens]) =>
          this.buildAuthHeaderForPolicy(policy, tokens)
        )
      );

      authHeaders.forEach((header) => {
        if (!header) {
          return;
        }

        const { key, value } = header;

        axiosConfig.headers[key] = value;
      });

      return axiosConfig;
    });

    let showedAlertForUnauthorized = false;
    this.axios.interceptors.response.use(
      async (axiosResponse) => {
        return axiosResponse;
      },
      async (error) => {
        if (Axios.isAxiosError(error) && error.response) {
          if (showedAlertForUnauthorized) {
            await new Promise(() => {
              // Noop
            });
          }

          if (error.response.status === 401) {
            // if error.response?.data.policies is empty, it means that the user is not logged in
            if (!error.response?.data.policies.length) {
              // this is for preventing showing multiple alerts
              showedAlertForUnauthorized = true;

              await window.EB.emitter.alert(
                window.EB.intl.formatMessage('__eb_user_login_session_expired'),
                'warning'
              );

              window.location.reload();
            }
          }

          if (
            error.response.status === 403 &&
            (!error.response.data.errorMessage ||
              error.response.data.errorMessage === 'error_forbidden')
          ) {
            error.response.data.errorMessage = '__eb_user_forbidden';
          }
        }

        return Promise.reject(error);
      }
    );
  }

  turnOnAutoRefreshToken(): void {
    setInterval(this.autoRefreshToken, AUTH_TOKEN_REFRESH_TIMEOUT);
  }

  private async buildAuthHeaderForPolicy(
    policy: string,
    tokens: AuthToken
  ): Promise<{ key: string; value: string } | null> {
    let accessToken = tokens.accessToken;

    try {
      if (Date.now() >= tokens.exp * 1000) {
        const newTokens = await window.EB.auth.refreshToken(
          policy,
          accessToken,
          tokens.refreshToken
        );

        if (!newTokens) {
          // TODO: Couldn't refresh token => should show a message or not?

          return null;
        }

        accessToken = newTokens.accessToken;

        this.updateAuthTokensForPolicies(
          policy,
          newTokens.accessToken,
          newTokens.refreshToken,
          newTokens.exp,
          newTokens.idToken
        );
      }
    } catch (error) {
      if (error instanceof InvalidTokenError) {
        this.removeAuthTokensForPolicy(policy);

        return null;
      }
    }

    return {
      key: `X-EB-AUTH-POLICY-${policy}`,
      value: `Bearer ${accessToken}`
    };
  }

  removeAuthTokensForPolicy(policy: string): void {
    const { [policy]: _, ...rest } = this.authTokensPolicies;

    this.authTokensPolicies = rest;

    localStorage.setItem(this.storageCacheKey, JSON.stringify(this.authTokensPolicies));

    const policyConfig = this.config.securityPolicies[policy];

    if ('group' in policyConfig && policyConfig.group) {
      const groupName = policyConfig.group;

      delete this.authTokensGroups[groupName];
      localStorage.removeItem(`__eb_auth_v2_policy_groups_${groupName}`);
    }
  }

  updateAuthTokensForPolicies(
    policy: string,
    accessToken: string,
    refreshToken: string,
    exp: number,
    idToken?: string
  ): void {
    const authTokens: AuthToken = {
      accessToken,
      refreshToken,
      exp,
      idToken
    };
    this.authTokensPolicies = {
      ...this.authTokensPolicies,
      [policy]: authTokens
    };

    localStorage.setItem(this.storageCacheKey, JSON.stringify(this.authTokensPolicies));

    const policyConfig = this.config.securityPolicies[policy];

    if ('group' in policyConfig && policyConfig.group) {
      const groupName = policyConfig.group;

      this.authTokensGroups[groupName] = { ...authTokens };
      localStorage.setItem(
        `__eb_auth_v2_policy_groups_${groupName}`,
        JSON.stringify(this.authTokensGroups[groupName])
      );
    }
  }

  getIdTokenOfPolicy(policy: string): string | undefined {
    return this.authTokensPolicies[policy]?.idToken;
  }

  autoRefreshToken(): void {
    Object.entries(this.authTokensPolicies)
      .filter(([_, { exp }]) => {
        return Date.now() + AUTH_TOKEN_REFRESH_TIMEOUT >= exp * 1000;
      })
      .forEach(([policyName, { accessToken, refreshToken }]) => {
        window.EB.auth.refreshToken(policyName, accessToken, refreshToken).then((tokens) => {
          if (!tokens) {
            return;
          }

          const { accessToken, refreshToken, exp, idToken } = tokens;

          this.updateAuthTokensForPolicies(policyName, accessToken, refreshToken, exp, idToken);
        });
      });
  }

  async get<T>(url: string, headers?: Record<string, string | number>): Promise<T> {
    try {
      const response = await this.axios.get<T>(url, { headers });

      return response.data;
    } catch (error) {
      throw new EBAPIError(error);
    }
  }

  async post<T>(
    url: string,
    data: FormData | Record<string | number, unknown> | Record<string | number, unknown>[],
    headers?: Record<string, string | number>
  ): Promise<T> {
    try {
      const response = await this.axios.post<T>(url, data, { headers });

      return response.data;
    } catch (error) {
      throw new EBAPIError(error);
    }
  }

  open(url: string): void {
    window.location.href = `${this.baseUrl}${url}`;
  }
}
